import React, { Component } from 'react';
import Countdown from 'react-countdown-now';
import ReactGA from 'react-ga';

var end = new Date();
end.setHours(23, 59, 59, 999);

export default class CTA1 extends Component {

  postAnalyticEvent() {
    console.log("1");
    ReactGA.event({
      category: 'naraschivanie-cta-click',
      action: 'click'
    });
    console.log("2");
  }

  render() {
    return (
      <div className="row m-3">
        <div className="col-md-12 cta-block text-center">
          <h1>До конца промо акции осталось: <Countdown date={end} daysInHours={true} /></h1>
          <h3 className="text-white">Запишитесь на наращивание сегодня и получите скидку 5% на стоимость всех услуг!</h3>
          <br />
          <div className="d-block mx-auto w-250">
            <form action="https://formspree.io/master@hair.moscow" method="post" className="naraschivanie-callback" encType="multipart/form-data">
              <div className="form-group mb-1">
                <input type="text" name="phone" className="hm-textbox" placeholder="Ваш телефон" />
                <button type="submit" className="hm-button" onClick={this.postAnalyticEvent}>Записаться</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}